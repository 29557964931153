export default (el, videoId) => {
    const embedMediaJsonpScript = document.createElement("script");
    embedMediaJsonpScript.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    embedMediaJsonpScript.async = true;
    document.body.appendChild(embedMediaJsonpScript);

    const wistiaPopoverEmbed = document.createElement("div");
    wistiaPopoverEmbed.classList.add(
        'wistia_embed',
        `wistia_async_${videoId}`,
        'seo=false',
        // 'videoFoam=true',
        'popover=true',
        'popoverContent=link',
    );
    document.body.append(wistiaPopoverEmbed);

    el.addEventListener('mouseenter', () => {
        loadWistiaPlayerScript();
    });

    el.addEventListener('click', (event) => {
        if (event.metaKey) {
            return;
        }

        event.preventDefault();

        loadWistiaPlayerScript();

        window._wq = window._wq || [];
        _wq.push({
            id: videoId,
            onReady(video) {
                video.play();
            },
        });
    });
};


function loadWistiaPlayerScript() {
    const src = 'https://fast.wistia.com/assets/external/E-v1.js';

    if (!document.querySelector(`script[src="${src}"]`)) {
        console.log('Loading Wistia Player script.');

        const wistiaPlayerScript = document.createElement('script');
        wistiaPlayerScript.src = src;
        wistiaPlayerScript.async = true;
        document.body.appendChild(wistiaPlayerScript);
    }
}
