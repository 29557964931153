export default () => ({
    toasts: [],
    visible: [],
    add({ type = 'info', text = '' }) {
        if (! ['success', 'info', 'warning', 'error'].includes(type)) {
            throw new Error(`"${type}" is not a valid toast type.`);
        }

        if (['', undefined, null].includes(text)) {
            throw new Error('Toast text can not be empty.')
        }

        const id = Date.now()
        this.toasts.push({ id, type, text })
        this.fire(id)
    },
    fire(id) {
        this.visible.push(this.toasts.find(toast => toast.id === id));
        const timeShown = 5000 * this.visible.length;
        setTimeout(() => {
            this.remove(id)
        }, timeShown);
    },
    remove(id) {
        const toast = this.visible.find(toast => toast.id === id)
        const index = this.visible.indexOf(toast)
        this.visible.splice(index, 1)
    },
});
