import Alpine from 'alpinejs';
import anchor from '@alpinejs/anchor';
import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import Cookies from "js-cookie";
import ToastComponent from "./site/components/ToastComponent.js";
import FormspreeComponent from "./site/components/FormspreeComponent.js";
import openChatWidgetWithPrePopulatedMessage from "./site/utils/openChatWidgetWithPrePopulatedMessage.js";
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import SmsLengthCalculatorComponent from './site/components/SmsLengthCalculatorComponent.js';
import tippy, { roundArrow } from 'tippy.js';
import openWistiaVideoInPopoverOnClick from "./site/utils/openWistiaVideoInPopoverOnClick.js";
import Bugsnag from '@bugsnag/js';

window['global']['keys']['bugsnag'] && Bugsnag.start({
    apiKey: window['global']['keys']['bugsnag'],
});

document.querySelectorAll('[data-tooltip-template]').forEach(element => {
    const templateId = element.getAttribute('data-tooltip-template');

    const template = document.getElementById(templateId);

    tippy(element, {
        content: template.innerHTML,
        allowHTML: true,
        theme: 'clearstream',
        // trigger: 'click',
        arrow: roundArrow,
        interactive: true,
    });
});

Alpine.plugin(anchor);
Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.data('ToastComponent', ToastComponent);
Alpine.data('FormspreeComponent', FormspreeComponent);
Alpine.data('SmsLengthCalculatorComponent', SmsLengthCalculatorComponent);
Alpine.directive('tooltip', (el, { expression, modifiers }) => {
    tippy(el, {
        content: expression,
        theme: modifiers.indexOf('dark') !== -1 ? 'clearstream-dark' : 'clearstream',
        placement: modifiers.find(modifier => modifier.match(/^(top|right|bottom|left)(-start|-end)?$/)) || 'top',
        // trigger: 'click',
        offset: [0, 12],
        // arrow: roundArrow,
    });
});

window.Alpine = Alpine;

Alpine.start();

window.PhotoSwipe = PhotoSwipe;
window.PhotoSwipeLightbox = PhotoSwipeLightbox;

(() => {
    const { searchParams } = new URL(location.href);
    if (searchParams.has('chat_message')) {
        openChatWidgetWithPrePopulatedMessage(searchParams.get('chat_message'))
    }
})();

Array.from(document.getElementsByTagName('a')).forEach(a => {
    let url;

    try {
        url = new URL(a.href);
    } catch (e) {
        return;
    }

    const { searchParams, hostname } = url;

    // Open external links in new tab.
    if (hostname !== location.hostname && a.target === '' && hostname) {
        a.target = '_blank';
    }

    if (hostname === location.hostname) {
        if (searchParams.has('chat_message')) {
            a.addEventListener('click', event => {
                if (!event.metaKey) {
                    event.preventDefault();
                    openChatWidgetWithPrePopulatedMessage(searchParams.get('chat_message'))
                }
            });
        }

        if (searchParams.has('popover_video_wistia_id')) {
            openWistiaVideoInPopoverOnClick(a, searchParams.get('popover_video_wistia_id'));

            // If user opens this magic link in a new tab, it should still work and open the video in popover.
            if (new URL(location.href).searchParams.get('popover_video_wistia_id') === searchParams.get('popover_video_wistia_id')) {
                a.scrollIntoView({ behavior: 'smooth', block: 'center' });
                a.click();
            }
        }
    }
});

Array.from(document.querySelectorAll('[data-popover-video-wistia-id]')).forEach(el => {
    const videoId = el.getAttribute('data-popover-video-wistia-id');
    openWistiaVideoInPopoverOnClick(el, videoId);
});

// Store information about user source.
if (!Cookies.get('cs_src')) {
    Cookies.set('cs_src', JSON.stringify({
        landing: location.href || null,
        referrer: document.referrer || null,
    }), {
        expires: 30, // days
        domain: location.hostname === 'clearstream.io'
            ? '.clearstream.io'
            : location.hostname,
    });
}
