// v3
const recaptchaSiteKey = '6Ld4TtYhAAAAAIj_pUwQX5Mr4shVjJd-L17gZGpV';

function getRecaptchaToken() {
    return new Promise(resolve => {
        grecaptcha.ready(function () {
            grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function (token) {
                resolve(token);
            });
        });
    });
}

export default ({ onSuccess }) => ({
    init() {
        const scriptUrl = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}&badge=bottomleft`;

        const script = document.createElement('script');
        script.src = scriptUrl;
        const style = document.createElement('style');
        // !!! IMPORTANT !!! https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
        style.innerText = '.grecaptcha-badge { visibility: hidden; }';
        document.body.appendChild(script);
        document.body.appendChild(style);
    },
    submitting: false,
    async submit(event) {
        event.preventDefault();

        const form = event.target;

        if (!form.checkValidity()) {
            return;
        }

        const onError = () => {
            this.$dispatch('toast', { type: 'error', text: 'There was an error submitting the form.' });
        };

        this.submitting = true;

        const formData = new FormData(form);

        try {
            formData.append('g-recaptcha-response', await getRecaptchaToken());
        } catch (e) {
            //
        }

        fetch(form.action, {
            method: form.method,
            body: formData,
            headers: {
                'Accept': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                onSuccess && onSuccess();
                form.reset();
            } else {
                onError();
            }
        }).catch(() => {
            onError();
        }).finally(() => {
            this.submitting = false;
        });
    },
});
