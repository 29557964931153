import {EncodedText,GSM7,UCS2} from "@clearstream/sms-encoding";

export default () => ({
    _text: new URL(location.href).searchParams.get('text') || '',
    get text() {
        return this._text;
    },
    set text(value) {
        this._text = value;

        const url = new URL(location.href);
        url.searchParams.set("text", value);
        history.replaceState({}, "", url);
    },
    get encodedText() {
        return new EncodedText(this.text);
    },
    get encodingName() {
        if (this.encodedText.getEncoding() instanceof GSM7) {
            return 'GSM-7';
        }
        if (this.encodedText.getEncoding() instanceof UCS2) {
            return 'UCS-2';
        }
        return null;
    },
    get textLength() {
        return this.encodedText.getLength();
    },
    get maxTextLengthForSegment() {
        return this.encodedText.getEncoding().getMaxTextLength(this.numberOfSegments);
    },
    get numberOfSegments() {
        return this.encodedText.getNumberOfSegments();
    },
});
